<template>
  <b-overlay
      :show="isLoading"
      rounded="sm"
      variant="white"
      opacity="0.85"
      spinner-variant="primary"
  >
    <b-container>
      <PageHeader class="mb-4 mt-4">
        Акции
      </PageHeader>

      <b-row v-if="promotions.length > 0">
        <b-col
          v-for="promotion in promotions"
          :key="promotion.id"
          cols="12"
          md="6"
          lg="4"
          class="mb-4"
        >
          <Promotion :promotion="promotion"/>
        </b-col>
      </b-row>
      <b-alert
        v-else
        show
        variant="warning"
        class="w-100"
      >
        Акции отсутствуют
      </b-alert>
    </b-container>
  </b-overlay>
</template>

<script>
import { getPromotions } from '../api/profi_club/promotions';
import Promotion from '../components/Promotion.vue';
import PageHeader from '../components/PageHeader.vue';

export default {
  name: 'Promotions',
  components: {
    Promotion,
    PageHeader,
  },
  data() {
    return {
      promotions: [],
      isLoading: true,
    };
  },
  async created() {
    const promotionsResult = await getPromotions();
    this.promotions = promotionsResult?.list
      ? promotionsResult.list.sort((a, b) => a.orderNumber - b.orderNumber) : [];
    this.isLoading = false;
  },
};
</script>
