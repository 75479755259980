import { axiosProfiClubApiWithCredentials } from '../index';

export async function getPromotions() {
  const urlSegment = 'promotions/promotion';
  const response = await axiosProfiClubApiWithCredentials.get(urlSegment);
  return response.data ? response.data : null;
}
export async function getPromotion(uuid) {
  const urlSegment = `promotions/promotion/${uuid}`;
  const response = await axiosProfiClubApiWithCredentials.get(urlSegment);
  return response.data ? response.data : null;
}
