<template>
  <div class="promotion">
    <div
      class="promotion__cover"
      :style="{backgroundImage: `url(${promotion.photo})`}"
    >
    </div>

    <div class="promotion__content">
      <h3 class="h6">{{ promotion.title }}</h3>

      <p v-if="promotion.description.length !== 0">
        {{ promotion.description | squeezeText(60) }}
      </p>

      <b-row align-h="between" align-v="center" class="mt-auto">
        <b-col cols="auto">
          <time :datetime="promotion.date">{{promotion.date | getDate}}</time>
        </b-col>
        <b-col cols="auto">
          <b-link :to="`/promotions/${promotion.uuid}`">
            Подробнее
            <b-icon icon="arrow-right"/>
          </b-link>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Promotion',
  props: ['promotion'],
};
</script>

<style lang="scss">
.promotion {
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(19, 57, 108, 0.25);
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__cover {
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &::before {
      content: '';
      padding-top: 56%;
      display: block;
    }
  }

  &__content {
    padding: 20px;
    height: 100%;
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}
</style>
